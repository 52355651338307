
















.bloak-digital-layout {
  .grid {
    padding: 1em;
  }
  .menu-section {
    margin-bottom: 1.3em;
    .menu-section--top {
      margin-bottom: 0.6em !important;
    }
    .menu-section--title {
      font-size: 2.2em;
    }
    .menu-section--subtitle .resto-editable {
      font-size: 0.9em;
    }
    .item-addon {
      font-size: 1em;
    }
  }

  &:not(.menu-editing-on) {
    .grid {
      color: white;
      background-color: hsl(0deg, 0%, 40%);
    }
    .menu-element--dots {
      border-bottom-color: white;
    }
    .menu-section {
      &.callout {
        border-color: #b0b0b0;
      }
    }
  }
}
